.phone-verification-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
}

.subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.5;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.3rem;
}

.input-field {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  outline: none;
  border-color: #4a90e2;
}

.button {
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button.primary {
  background-color: #4a90e2;
  color: white;
}

.button.primary:hover {
  background-color: #3a7bc8;
}

.button.secondary {
  background-color: #f0f0f0;
  color: #333;
}

.button.secondary:hover {
  background-color: #e0e0e0;
}

.message {
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  font-size: 0.95rem;
}

.message.success {
  background-color: #c6f6d5;
  color: #276749;
}

.message.error {
  background-color: #fed7d7;
  color: #9b2c2c;
}

/* New styles for authenticated view */
.channel-info {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.channel-info p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #495057;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.button-container .button {
  flex: 1;
  margin: 0 0.5rem;
}

.button-container .button:first-child {
  margin-left: 0;
}

.button-container .button:last-child {
  margin-right: 0;
}

@media (max-width: 480px) {
  .phone-verification-container {
    margin: 1rem;
    padding: 1rem;
    border-radius: 8px;
  }

  .channel-table {
    display: block;
    overflow-x: auto;
  }

  .channel-table th,
  .channel-table td {
    padding: 1rem;
  }

  .visit-button {
    padding: 0.4rem 1rem;
    font-size: 0.85rem;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .subtitle {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}

.channel-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}

.channel-table th,
.channel-table td {
  padding: 1.2rem 1.5rem;
  text-align: left;
  border-bottom: 1px solid #edf2f7;
}

.channel-table th {
  background-color: #f8fafc;
  color: #4a5568;
  font-weight: 500;
  font-size: 0.95rem;
}

.channel-table tr:last-child td {
  border-bottom: none;
}

.channel-table tr:hover {
  background-color: #f8fafc;
}

.channel-table th:first-child,
.channel-table td:first-child {
  width: 100px;
  text-align: center;
}

.channel-details {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.channel-details div {
  font-size: 0.95rem;
  color: #2d3748;
  line-height: 1.5;
}

.channel-details strong {
  color: #718096;
  margin-right: 0.75rem;
  font-weight: 500;
}

.visit-button {
  padding: 0.5rem 1.2rem;
  font-size: 0.9rem;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(66, 153, 225, 0.2);
}

.visit-button:hover {
  background-color: #3182ce;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(66, 153, 225, 0.3);
}

.visit-button:active {
  transform: translateY(0);
}